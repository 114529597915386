<template>
  <div class="orderList">
    <en-table-layout :tableData="tableData.data" :loading="loading" :span-method="orderSpanMethod">
      <template slot="toolbar">
        <el-form-item label="成团状态">
          <el-select style="width: 110px" v-model="params.activity_group_by_status" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="待成团" value="WAIT_GROUP" />
            <el-option label="已成团" value="GROUPED" />
            <el-option label="未成团(已取消)" value="CANCELLED" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="shopInfo.shop_type==3" label="来源应用" class="col-auto">
          <el-select style="width: 100px" v-model="params.mall_type" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="现金商城" :value="2" />
            <el-option label="双选商城" :value="5" />
          </el-select>
        </el-form-item>
        <el-form-item label="下单日期">
          <el-date-picker style="width: 200px" v-model="params.order_time_range" type="daterange" :editable="false"
            unlink-panels size="small" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleDateChange" :picker-options="{
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
              shortcuts: MixinPickerShortcuts,
            }"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input style="width: 300px" v-model="kw" size="small" placeholder="请输入关键字进行搜索" clearable>
            <el-select style="width: 110px;" v-model="params.key_words" slot="prepend">
              <el-option label="订单编号" value="order_sn"></el-option>
              <el-option label="买家账号" value="mobile_account"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="收货人手机号" value="ship_mobile"></el-option>
              <el-option label="储值卡号" value="card_number"></el-option>
              <el-option label="备注信息" value="shop_remake"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="GET_OrderList('search')" size="small" type="primary"
            :disabled="loading">搜索</el-button>
        </el-form-item>
        <div class="col"></div>
        <el-form-item>
          <el-button @click="handleExportOrders" size="small" type="primary" :disabled="loading">导出订单</el-button>
        </el-form-item>
      </template>
      <!-- 总订单表格-->
      <template slot="table-columns">
        <el-table-column label="订单编号" width="140" fixed="left" align="center">
          <template slot-scope="{row}">
              {{ row.trade_sn }}<br/>
              <el-tag v-if="row.activity_group_by_id" size="mini" type="danger" effect="plain">多人拼团</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="订单总额" width="100" fixed="left">
          <template slot-scope="{row}">{{ row.group_activity_price | unitPrice('￥') }}</template>
        </el-table-column>

        <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left">
          <template slot-scope="{row}">
            <div style="position: relative">
              <el-popover placement="right" trigger="hover">
                <img :src="row.image || row.goods_image" style="width: 300px;" alt />
                <img slot="reference" :src="row.image || row.goods_image" class="goods-cover" alt />
              </el-popover>
              <img class="goods-tag" :src="getRowTagImage(row)">
            </div>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" show-overflow-tooltip width="200" fixed="left">
          <template slot-scope="{row}">
            <span class="ml-2">{{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</span>
          </template>
        </el-table-column>

        <el-table-column label="商品单价" width="100" align="center">
          <template slot-scope="{row}">
            <span v-if="row.price">{{ row.price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>

        <el-table-column label="商品数量" width="80" align="center">
          <template slot-scope="{row}">
            <span class="ml-2">{{ row.goods_num }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="shopInfo.shop_type == 3" label="收货人姓名" width="100" prop="consignee_name" align="center" />
        <el-table-column v-if="shopInfo.shop_type == 3" label="收货人手机号" width="120" prop="consignee_mobile" align="center" />
        <el-table-column v-if="shopInfo.shop_type == 3" label="收货地址" width="300" show-overflow-tooltip align="center">
          <template slot-scope="{row}">
            {{ row.consignee_province ? row.consignee_province + row.consignee_city + row.consignee_county +
              row.consignee_town +
              row.consignee_address : ''
            }}
          </template>
        </el-table-column>
        <el-table-column label="供货方" width="80">
          <template slot-scope="{row}">
            <span>{{ orderSourceMap[row.order_source] }}</span>
          </template>
        </el-table-column>

        <el-table-column label="成团状态" width="100" align="center">
          <template slot-scope="{row}">
            <template>
              {{ row.activity_group_by_status|groupStatusFilters }}
              <div v-if="row.hangup_status === 1" style="color: red;">(店铺挂起)</div>
              <div v-if="row.hangup_status === 2" style="color: red;">(企业挂起)</div>
              <div v-if="row.hangup_status === 3" style="color: red;">(供应商挂起)</div>
            </template>
          </template>
        </el-table-column>

        <el-table-column v-if="shopInfo.shop_type == 3" label="来源应用" align="center" width="200">
          <template slot-scope="{row}">
            <span v-if="row.mall_type == 2">现金商城</span>
            <span v-if="row.mall_type == 5">双选商城</span>
          </template>
        </el-table-column>
        <el-table-column label="下单时间" align="center" width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="下单账号" align="center" prop="mobile" width="150" show-overflow-tooltip
          class-name="font-family-tttgb">
          <template slot-scope="{row}">
            <p>{{ row.mobile || '' }}</p>
            <p>{{ row.login_account || '' }}</p>
          </template>
        </el-table-column>
        <el-table-column label="下单备注信息" align="center" width="160">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
              <div class="remark-box">
                {{ row.remark }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="备注信息" show-overflow-tooltip width="120">
          <template slot-scope="{row}">
            {{ row.shop_remake }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" :width="200" align="center">
          <template slot-scope="{row}">
            <el-button type="primary" size="small" @click="openRemarkDialog(row)">备注</el-button>
            <el-button type="primary" size="small" @click="handleOperateOrder(row)">查看详情</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入不超100字的备注信息" maxlength="100"
          v-model="remarkForm.shop_remake"></el-input>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import { Foundation } from "@/../ui-utils";
import { mergeSkuList } from "@/utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import { mapGetters } from "vuex";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import TagJD from '@/assets/goods/tag-jd.png';
import TagJX from '@/assets/goods/tag-jx.png';
import TagZY from '@/assets/goods/tag-zy.png';
import statusZiti from '@/assets/goods/ziti.png';
export default {
  name: "activityGroupBuyOrder",
  components: { EnTableLayout, XDialog },
  computed: {
    ...mapGetters(["shopInfo"]),
    timeDefault () {
      var date = new Date();
      var s1 =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() - 1);
      return s1;
    }
  },
  filters: {
    groupStatusFilters (status) {
      switch (status) {
        case 'WAIT_GROUP':
          return '待成团'
        case 'GROUPED':
          return '已成团'
        case 'CANCELLED':
          return '未成团（已取消）'
      }
    },
    tradeformFilter (status) {
      const statusMap = {
        1: "普通商品订单",
        2: "礼包订单",
        3: "计次卡订单",
        4: "宅配卡订单"
      };
      return statusMap[status];
    },
    paymentTypeFilter (val) {
      return val === "ONLINE" ? "在线支付" : "线下支付";
    }
  },
  data () {
    return {
      orderSourceMap: {
        0: '平台',
        1: '京东',
        3: '自有',
        4: '蛋糕叔叔',
        5: '企业自有供应商'
        // 新增其他来源时，在这里添加
      },
      currentTab: "0",
      remarkDialog: $xDialog.create({
        title: "备注信息",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editRemark
      }),
      // 备注信息
      remarkForm: {
        trade_sn: "",
        platform: "",
        shop_remake: "",
      },
      keywords: {
        keys: [
          ["order_sn", "订单编号"],
          ["", "买家账号"],
          ["", "商品名称"],
          ["", "物流单号"],
          ["", "物流单号"],
          ["", "收货人手机号"]
        ]
      },

      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        key_words: "order_sn",
        order_sn: "",
        mall_type: "",//来源应用
        trade_type: "",//订单类型
        logistics_status: undefined,
        payment_type: "",
        order_time_range: [],
        activity_group_by_filter: 'ONLY_INCLUDE',//只展示拼团订单
        activity_group_by_show_level: 'NONE',
        activity_group_by_status:'',//拼团状态
      },
      // 列表数据
      tableData: {},
      // 高级搜索数据
      advancedForm: {},
      kw: ""
    };
  },
  mounted () {
    if (sessionStorage.getItem('orderListActiveName')) {
      this.currentTab = sessionStorage.getItem('orderListActiveName')
    }
    if (this.shopInfo.shop_type === 1) {//现金商城
      this.params["shop_type"] = 1;
    } else if (this.shopInfo.shop_type === 3) {//企业端
      this.params["shop_type"] = 2;
    }
    this.GET_OrderList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
    
  },
  activated () {
    if (sessionStorage.getItem('orderListActiveName')) {
      this.currentTab = sessionStorage.getItem('orderListActiveName')
    }
    this.currentTab = sessionStorage.getItem('orderListActiveName')
    this.GET_OrderList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  beforeDestroy () {
    sessionStorage.removeItem('orderListActiveName')
  },
  methods: {
  /**打开备注弹窗 */
    openRemarkDialog (row) {
      this.remarkDialog.display();
      this.remarkForm.trade_sn = row.trade_sn; //订单编号
      this.remarkForm.shop_remake = '';
    },
    /**编辑保存备注 */
    editRemark () {
      if (this.remarkForm.shop_remake === "" || this.remarkForm.shop_remake === null) {
        return this.$message.error("备注信息不能为空");
      }
      this.remarkForm.shop_remake = this.remarkForm.shop_remake.replace(/\n/g, "/n");
      API_order.updateShopRemake(this.remarkForm).then(res => {
        this.$message.success("保存成功");
        this.GET_OrderList();
      });
    },
    orderSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (this.currentTab === "0") {
        if (columnIndex >= 2 && columnIndex <= 12) {
          return {
            rowspan: 1,
            colspan: 1
          };
        } else {
          return row.span;
        }
      } else {
        if (columnIndex >= 3 && columnIndex <= 13) {
          return {
            rowspan: 1,
            colspan: 1
          };
        } else {
          return row.span;
        }
      }
    },
    MixinGetOrderStatus (status) {
      switch (status) {
        case "NOT_APPLY":
          return "未申请";
        case "APPLY":
          return "已申请";
        case "PASS":
          return "审核通过";
        case "REFUSE":
          return "审核未通过";
        case "EXPIRED":
          return "已失效或不允许申请售后";
        case "REFUNDFAIL":
          return "退款失败";
        case "COMPLETED":
          return "完成";
        case "WAIT_SEND_BACK":
          return "待寄回";
        case "SEND_BACK":
          return "买家寄回";
        case "SELLER_SHIP":
          return "卖家发货";
        case "REFUSE_SHIP":
          return "卖家拒绝发货";
        default:
          return "";
      }
    },
    getTargetTime (t) {
      var d = t.split(" ")[0],
        date = new Date();
      date.setYear(d.split("-")[0]);
      date.setMonth(d.split("-")[1] - 1);
      date.setDate(d.split("-")[2]);
      return date.getTime();
    },
    handleDateChange (val) {
      console.log(this.params.order_time_range);
      console.log(val);
      if (val) {
        this.params.start_time = parseInt(val[0].getTime());
        this.params.end_time = parseInt(val[1].getTime());
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_OrderList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList();
    },

    /** 查看、操作订单 */
    handleOperateOrder (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "groupBuyingDetail",
        params: {
          sn: row.trade_sn,
        }
      });
    },
    /** 导出订单 */
    handleExportOrders () {
      this.params[this.params.key_words] = this.kw;
      const params = this.MixinClone(this.params);
      if (params.start_time && params.end_time) {
        params.start_time = parseInt(params.start_time / 1000);
        params.end_time = parseInt(params.end_time / 1000) + 86399;
      }
      this.loading = true;
      delete this.params[this.params.key_words]
      delete params.order_time_range;
      delete params.key_words;
      // const { advancedForm: params } = this;
      this.loading = true;
      params.page_no = 1;
      params.page_size = 99999;
      API_order.getOrderList(params).then(response => {
        const tableData = mergeSkuList(response.data, 1);
        this.loading = false;
        const json = {
          sheet_name: "订单列表",
          sheet_values: tableData.map(item => ({
            订单编号: item.trade_sn,
            订单总额: item.group_activity_price,
            商品名称: item.goods_name,
            商品单价: item.price,
            商品数量: item.goods_num,
            供货方: this.orderSourceMap[item.order_source],
            成团状态: this.groupStatusFomate(item.activity_group_by_status),
            下单时间: Foundation.unixToDate(item.create_time),
            下单账号: (item.mobile || '') + (item.mobile && item.login_account ? '\n' : '') + (item.login_account ? item.login_account : ''),
            下单人所属分组:item.group_name,
            下单备注信息: item.remark ? item.remark : '',
            备注信息: item.shop_remake?item.shop_remake:''
          }))
        };
        if (this.shopInfo.shop_type == 3) {
          json.sheet_values=tableData.map(item => ({
            订单编号: item.trade_sn,
            订单总额: item.group_activity_price,
            商品名称: item.goods_name,
            商品单价: item.price,
            商品数量: item.goods_num,
            收货人姓名:item.consignee_name,
            收货人电话: item.consignee_mobile,
            收货地址:  item.consignee_province ? item.consignee_province + item.consignee_city + item.consignee_county +item.consignee_town + item.consignee_address : '',
            供货方: this.orderSourceMap[item.order_source],
            成团状态: this.groupStatusFomate(item.activity_group_by_status),
            来源应用: item.mall_type==2?'现金商城':'双选商城',
            下单时间: Foundation.unixToDate(item.create_time),
            下单账号: (item.mobile || '') + (item.mobile && item.login_account ? '\n' : '') + (item.login_account ? item.login_account : ''),
            下单备注信息: item.remark ? item.remark : '',
            备注信息: item.shop_remake?item.shop_remake:''
          }))
        }
        this.MixinExportJosnToExcel(json, "订单列表");
      });
    },
    groupStatusFomate (status) {
      switch (status) {
        case 'WAIT_GROUP':
          return '待成团'
        case 'GROUPED':
          return '已成团'
        case 'CANCELLED':
          return '未成团（已取消）'
      }
    },
    pricetotal (item) {
      if (item.seller_id == item.shop_id) {
        return '￥0'
      } else {
        if (item.total_price_platform) {
          return Foundation.formatPrice(item.total_price_platform)
        } else {
          return Foundation.formatPrice(
            item.platform_price +
            item.platform_freight_price
          );
        }
      }
    },
    /** 获取订单列表 */
    GET_OrderList (type) {
      if (type === "search") {
        this.params.page_no = 1;
      }
      this.params[this.params.key_words] = this.kw;
      const params = this.MixinClone(this.params);
      if (params.start_time && params.end_time) {
        params.start_time = parseInt(params.start_time / 1000);
        params.end_time = parseInt(params.end_time / 1000) + 86399;
      }
      this.loading = true;
      delete this.params[this.params.key_words]
      delete params.order_time_range;
      delete params.key_words;
      API_order.getOrderList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
          this.tableData.data = mergeSkuList(response.data, 1);
          delete params[this.params.key_words]
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getRowTagImage (row) {
      if (row.delivery_method == 2 || row.delivery_method == 3) return statusZiti
      switch (row.order_source) {
        case 3:  // 自有商品
          return TagZY;
        case 5:  // 企业自有供应商商品
          return TagZY;
        case 1:  // 京东商品
          return TagJD;
        case 0:  // 普通商品（精选）
          return TagJX;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .cell {
  padding-left: 0 !important;
}

::v-deep .el-table__fixed-body-wrapper {
  top: 34px !important;
}

.conceal {
  white-space: nowrap; //不换行
  overflow: hidden; //超出则隐藏
}

/deep/.el-tabs--card>.el-tabs__header {
  margin: 0;
}

.orderList-tab {
  background-color: #fff;

  .el-tab-pane {
    height: calc(100vh - 126px);
  }
}

.remark-box {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
